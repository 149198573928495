import { FC } from "react";

interface IconFacebookHoverProps {
  className?: string;
}

const IconFacebookHover: FC<IconFacebookHoverProps> = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.8012 19.5066V22.3263H30.2883L29.7361 26.1246H26.8012V34.8757C26.2127 34.9574 25.6107 35 24.9996 35C24.2942 35 23.6015 34.9438 22.9269 34.8349V26.1246H19.7109V22.3263H22.9269V18.8763C22.9269 16.7359 24.6614 15 26.8021 15V15.0018C26.8084 15.0018 26.8139 15 26.8202 15H30.2892V18.285H28.0225C27.3488 18.285 26.8021 18.8319 26.8021 19.5057L26.8012 19.5066Z"
        fill="black"
      />
    </svg>
  );
};

export default IconFacebookHover;
