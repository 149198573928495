import { FC } from "react";

interface IconLinkedinProps {
  className?: string;
}

const IconLinkedin: FC<IconLinkedinProps> = () => {
  return (
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M15.2471 18.7496C14.756 18.2937 14.5117 17.7293 14.5117 17.0577C14.5117 16.3862 14.7573 15.7969 15.2471 15.3396C15.7382 14.8837 16.3703 14.655 17.1449 14.655C17.9194 14.655 18.5268 14.8837 19.0166 15.3396C19.5077 15.7956 19.7519 16.3692 19.7519 17.0577C19.7519 17.7463 19.5064 18.2937 19.0166 18.7496C18.5255 19.2056 17.9024 19.4343 17.1449 19.4343C16.3873 19.4343 15.7382 19.2056 15.2471 18.7496ZM19.3392 21.3653V35.345H14.9231V21.3653H19.3392Z"
    fill="#FEFFFC"
  />
  <path
    d="M34.0418 22.7463C35.0045 23.7915 35.4851 25.2261 35.4851 27.0526V35.0981H31.2912V27.6196C31.2912 26.6985 31.0521 25.9825 30.5754 25.473C30.0987 24.9634 29.456 24.7074 28.6515 24.7074C27.8469 24.7074 27.2042 24.9621 26.7275 25.473C26.2508 25.9825 26.0117 26.6985 26.0117 27.6196V35.0981H21.793V21.3261H26.0117V23.1526C26.4389 22.5438 27.0149 22.063 27.7385 21.7089C28.4621 21.3549 29.2758 21.1785 30.1809 21.1785C31.7927 21.1785 33.0805 21.7011 34.0418 22.745V22.7463Z"
    fill="#FEFFFC"
  />
</svg>
  );
};

export default IconLinkedin;
