import { FC } from "react";

interface IconTikTokProps {
  className?: string;
}

const IconTikTok: FC<IconTikTokProps> = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.7608 22.3308C31.1806 23.2165 32.9201 23.7377 34.7987 23.7377V20.5829C34.4432 20.583 34.0886 20.5506 33.7407 20.4863V22.9695C31.8622 22.9695 30.123 22.4484 28.7027 21.5627V28.0007C28.7027 31.2213 25.711 33.8319 22.0207 33.8319C20.6437 33.8319 19.3639 33.4687 18.3008 32.8456C19.5142 33.9283 21.2064 34.6 23.0785 34.6C26.769 34.6 29.7609 31.9894 29.7609 28.7686V22.3308H29.7608ZM31.0659 19.148C30.3403 18.4562 29.8638 17.5621 29.7608 16.5737V16.168H28.7581C29.0105 17.4242 29.8713 18.4975 31.0659 19.148ZM20.6349 30.3742C20.2295 29.9104 20.0104 29.3429 20.0114 28.7594C20.0114 27.2864 21.3798 26.0921 23.068 26.0921C23.3827 26.092 23.6954 26.1341 23.9953 26.2171V22.9918C23.6449 22.9499 23.2912 22.9321 22.9377 22.9386V25.449C22.6376 25.366 22.3247 25.3238 22.01 25.3241C20.3218 25.3241 18.9534 26.5183 18.9534 27.9915C18.9534 29.0331 19.6374 29.9349 20.6349 30.3742Z"
        fill="white"
      />
      <path
        d="M28.7049 21.5627C30.1251 22.4483 31.8643 22.9695 33.7428 22.9695V20.4862C32.6943 20.2913 31.766 19.8131 31.068 19.148C29.8734 18.4974 29.0126 17.4242 28.7603 16.168H26.1267V28.7685C26.1207 30.2374 24.7546 31.4269 23.07 31.4269C22.0773 31.4269 21.1954 31.0139 20.6368 30.3742C19.6394 29.9349 18.9554 29.033 18.9554 27.9915C18.9554 26.5185 20.3238 25.3242 22.012 25.3242C22.3355 25.3242 22.6472 25.3681 22.9397 25.4491V22.9387C19.3142 23.0041 16.3984 25.5892 16.3984 28.7686C16.3984 30.3557 17.1245 31.7944 18.303 32.8457C19.3661 33.4687 20.6459 33.832 22.0229 33.832C25.7133 33.832 28.7049 31.2212 28.7049 28.0007V21.5627H28.7049Z"
        fill="white"
      />
      <path
        d="M33.7422 20.4863V19.8148C32.7966 19.8161 31.8696 19.585 31.0674 19.148C31.7776 19.8265 32.7127 20.2943 33.7422 20.4863ZM28.7596 16.168C28.7355 16.048 28.717 15.9271 28.7042 15.8058V15.4H25.0679V28.0007C25.0621 29.4694 23.6961 30.6589 22.0113 30.6589C21.5167 30.6589 21.0497 30.5564 20.6361 30.3744C21.1947 31.014 22.0766 31.4269 23.0694 31.4269C24.7538 31.4269 26.1201 30.2376 26.126 28.7686V16.168H28.7596ZM22.9392 22.9387V22.2239C22.6353 22.1877 22.329 22.1695 22.0223 22.1696C18.3316 22.1696 15.3398 24.7803 15.3398 28.0007C15.3398 30.0196 16.5157 31.799 18.3024 32.8456C17.1239 31.7944 16.3979 30.3556 16.3979 28.7685C16.3979 25.5892 19.3135 23.0041 22.9392 22.9387Z"
        fill="white"
      />
    </svg>
  );
};

export default IconTikTok;
