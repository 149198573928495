import {
  apiRequest,
  ReturnTypeWithPagination,
  VehicleMake,
  API_ENDPOINTS,
  VehicleMakeGetDTO,
  timeAsSeconds,
  DataReturnType,
} from "@alba-cars/common-modules";
import QueryString from "qs";

import { PaginatedResponse } from "@/components/common/forms/FormAutoComplete";

export const getVehicleMakes = async (filters?: VehicleMakeGetDTO & { relevant?: string }) => {
  const params = {
    search: filters?.filter?.search || "",
    options: {
      page: filters?.options?.page || 1,
      limit: filters?.options?.limit || 20,
    },
    relevant: filters?.relevant ?? null,
  };

  const queryString = QueryString.stringify(params, {
    encode: true,
    arrayFormat: "brackets",
    allowDots: false,
    skipNulls: true,
  });

  const response = await apiRequest<ReturnTypeWithPagination<VehicleMake[]>>(
    `${API_ENDPOINTS.vehicleMake.getAll.toString()}?${queryString}`,
    {
      next: {
        revalidate: timeAsSeconds({ hours: 1 }),
        tags: ["vehicle-makes"],
      },
    },
  );

  return response;
};

export const getVehicleMakesOptions = async (
  filters?: VehicleMakeGetDTO & { relevant?: string },
): Promise<(VehicleMake & { label: string; value: string })[]> => {
  const response = await getVehicleMakes(filters);

  const makes = response.data?.result ?? [];

  return makes.map((make) => ({
    ...make,
    label: make.name,
    value: make.id,
  }));
};

export const getVehicleMakesPaginatedOptions = async (
  query: string,
  page: any,
): Promise<PaginatedResponse<VehicleMake & { label: string; value: string }>> => {
  const dto = new VehicleMakeGetDTO();
  dto.filter = {
    search: query,
    validate(): string[] {
      return [];
    },
  };
  dto.options = {
    page: page,
    limit: 20,
    validate(): string[] {
      return [];
    },
  };
  const response = await getVehicleMakes(dto);

  const models = response.data?.result ?? [];
  const total = response.data?.total;
  const currentPage = response.data?.page;

  return {
    data: models.map((model) => ({
      ...model,
      label: model.name,
      value: model.id,
    })),
    meta: {
      currentPage: currentPage || 1,
      totalPages: total || 1,
      hasNextPage: (currentPage || 1) < (currentPage || 1),
    },
  };
};

export const getFooterMakes = async () => {
  const endpoint = `${API_ENDPOINTS.vehicleMake.getAll.toString()}?filter%5BshowInFooter%5D=true`;

  const response = await apiRequest<ReturnTypeWithPagination<VehicleMake[]>>(endpoint, {
    next: {
      revalidate: timeAsSeconds({ hours: 1 }),
      tags: ["vehicle-makes-footer"],
    },
  });

  if (!response.success) {
    console.error("Failed to fetch footer makes:", response.message ?? response.error?.message);
    return [];
  }

  return response.data?.result ?? [];
};

export const getMakeBySlug = async (slug: string) => {
  const endpoint = `${API_ENDPOINTS.vehicleMake.getOneBySlug(slug).toString()}`;

  const response = await apiRequest<DataReturnType<VehicleMake>>(endpoint);

  return response.data;
};
